<template>
  <div
      class="
      border-0
      pt-5
      d-flex
      justify-content-between
      flex-column flex-xxl-row
      align-items-start align-items-xxl-center
    "
  >
    <!--THIS ONLY WILL BE USED ONLY ON CATALOG PAGE-->
    <div v-if="showCatalogFilter" class="ms-xxl-4" id="hide-media">
      <TopCategoriesMenu
          :categories="categories"
          @filter="updateCatalog"
      />
    </div>

    <h3 class="ms-1 ms-xxl-2">
      <span class="card-label fw-bolder fs-3 mb-1">{{ tableName }}</span>
    </h3>
    <div
        class="
        flex-column flex-xxl-row
        d-flex
        justify-content-between
        align-items-start align-items-xxl-center
        me-9
        ms-xxl-0 ms-md-1 ms-1
      "
    >

      <!--begin::Label-->
      <div v-if="showFilters && showOnlyProdsWithDocsFilter" class="me-6">
        <label for="check0" class="fs-6 fw-bold" style="display: flex; align-items: center;"
          >Filtrar por ficha
          <span v-if="loading" style="margin-left:0.2rem;">
                  <i class="fas fa-spinner fa-spin text-dark"></i></span>
          <input
            v-if="!loading"
            type="checkbox"
            class="primary-input"
            value="check"
            @change="filterWithDocHandler"
            :checked="filterWithDoc"
            style="transform: scale(1.1);cursor: pointer;margin-left:0.5rem;"
          /></label
        >
      </div>

      <!--input to start and end date same line-->
      <div v-if="showFilters && showDatesFilters" class="me-6">
        <span class="fs-6 d-xxl-flex">
          <span class="fw-bold" style="align-self:center;">De:&nbsp;</span>
          <input
              type="date"
              class="form-control form-control-sm form-control-solid me-1"
              :max="endDate"
              v-model="startDate"
              @change="queryForOccurences"
              
          />
          <span class="fw-bold" style="align-self:center;">Até:&nbsp;</span>
          <input
              type="date"
              class="form-control form-control-sm form-control-solid m-xxl-0 my-1 me-1"
              :min="startDate"
              v-model="endDate"
              @change="queryForOccurences"
          />
        </span>
      </div>

      <!--begin::Label-->
      <label v-if="showFilters && filterStatus.length" class="fs-6 fw-bold">Filtrar por:&nbsp;</label>

      <!--begin::Select-->
      <div v-if="showFilters && filterStatus.length" class="me-6 my-1 mb-xxl-0 my-1">
        <Field
            name="status"
            data-control="select2"
            data-hide-search="true"
            data-placeholder="Selecione o estado..."
            class="form-select form-control-sm form-select-solid fs-7 my-1 text-muted"
            style="min-width: 150px; max-width: 150px;"
            as="select"
            @change="updateStatus"
            v-model="status"
        >
          <option class="" value="">Todos</option>
          <option
              v-for="(item, index) in filterStatus"
              :key="index"
              :value="item"
          >
            {{ item }}
          </option>
        </Field>
      </div>

      <div v-if="showFilters " class="me-5 my-1">
        <span
            class="
            svg-icon svg-icon-3 svg-icon-gray-500
            position-absolute
            translate-middle
            ps-10
            pt-11
          "
        >
          <span class="svg-icon svg-icon-2">
            <inline-svg src="media/icons/duotune/general/gen004.svg"/>
          </span>
        </span>
        <input
            type="text"
            class="form-control form-control-sm form-control-solid ps-10"
            style="min-width: 200px; max-width: 200px;"
            v-model="searchInput"
            v-on:keyup.enter="queryForOccurences"
            placeholder="Pesquisar..."
        />
      </div>

      <div class="my-1"
      >
        <v-pagination
            v-if="pages"
            style="color: red; justify-content: end;"
            v-model="page"
            :pages="pages"
            :range-size="0"
            :hideFirstButton="true"
            :hideLastButton="true"
            active-color="#efefef"
            @update:modelValue="paginatorHandler"
        />
      </div>

      <!--end::Menu-->
    </div>
  </div>
</template>

<script>
import VPagination from "@hennge/vue3-pagination";
import "@hennge/vue3-pagination/dist/vue3-pagination.css";
import {ErrorMessage, Field, Form} from "vee-validate";
import TopCategoriesMenu from "@/components/Products/TopCategoriesMenu_.vue";

export default {
  name: "CustomTableTopFilter",
  components: {
    Field,
    VPagination,
    TopCategoriesMenu,
  },
  data() {
    return {
      page: 1,
      status: "",
      searchInput: "",
      startDate: "",
      endDate: "",
    };
  },
  watch: {
    pageProp() {
      this.page = this.pageProp;
    },
  },
  props: {
    tableName: String,
    pageProp: Number,
    pages: Number,
    filterStatus: Array,
    showFilters: {
      type: Boolean,
      default: true,
    },
    showDatesFilters: {
      type: Boolean,
      default: true,
    },
    showCatalogFilter: {
      type: Boolean,
      default: false,
    },
    categories: Array,
    showOnlyProdsWithDocsFilter: {
      type: Boolean,
      default: false,
    },
    filterWithDoc: {
      Boolean,
      default: false,
    },
    loading: Boolean,
  },
  emit: ["paginatorHandler", "updateStatus", "queryForOccurences", "updateCatalog", "filterWithDocHandler"],

  methods: {
    getMaxWidth() {
      if (this.pages) {
        return {
          minWidth: "250px",
        };
      }
    },

    /*
    * ------------------EMITS------------------
    */

    paginatorHandler() {
      this.$emit("paginatorHandler", this.page);
    },
    updateStatus() {
      this.$emit("updateStatus", this.status);
    },
    queryForOccurences() {
      this.$emit("queryForOccurences", {
        searchInput: this.searchInput,
        startDate: this.startDate, 
        endDate: this.endDate
      });
    },
    updateCatalog(filter) {
      this.$emit("updateCatalog", filter);
    },
    filterWithDocHandler() {
      if(this.loading) return;
      this.$emit("filterWithDocHandler");
    },
  },
  mounted() {
    /*let startDate = new Date();
    startDate.setFullYear(startDate.getFullYear() - 1);
    this.startDate = startDate.toLocaleDateString("pt-PT").replace(/\//g, "-").split("-").reverse().join("-"); */
  },
};
</script>

<style>
body {
  overflow-x: hidden;
}

@media screen and (max-width: 850px) {
  #hide-media {
    height: 0;
  }
}

</style>
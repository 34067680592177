<template>
  <div class="container">
    <nav>
      <div class="menu-icons">
        <i class="fas fa-bars" id="bars-show-hide" @click="toggleBarMenu()"></i>
        <i
          class="fas fa-times"
          id="times-show-hide"
          @click="toggleBarMenu()"
        ></i>
      </div>
      <ul class="nav-list" id="nav-list-show-hide">
        <li class="menu-tittle">Categorias</li>
        <li>
          <a class="menu">
            <span>
              <span class="categg me-2"><b>Categorias:</b></span>
              <span>{{ categories.length === 0 ? "Sem categorias" : dispL1 }}</span>
              <i v-if="dispL2" class="fas fa-caret-right mx-2"></i>
              <br v-if="dispL2 && isSmallerScreen">
              <span>{{ dispL2 }}</span>
              <i v-if="dispL3" class="fas fa-caret-right mx-2"></i>
              <br v-if="dispL3 && isSmallerScreen">
              <span>{{ dispL3 }}</span>
              <i v-if="dispL4" class="fas fa-caret-right mx-2"></i>
              <br v-if="dispL4 && isSmallerScreen">
              <span>{{ dispL4 }}</span>
              <i v-if="dispL5" class="fas fa-caret-right mx-2"></i>
              <br v-if="dispL5 && isSmallerScreen">
              <span>{{ dispL5 }}</span>
            </span>
          </a>
          <ul v-if="level1Obj.length !== 0" class="sub-menu">
            <li v-if="dispL1 != 'Todas'">
              <a @click="filter('Todas')">Todas</a>
            </li>
            <li v-for="(level1, i1) in Object.keys(level1Obj)" :key="i1">
              <a @click="filter(level1)"
                >{{ level1 }}
                <i
                  v-if="Object.keys(level1Obj[level1]).length !== 0"
                  :class="[
                    'fas',
                    isSmallerScreen ? 'fa-caret-down' : 'fa-caret-right',
                  ]"
                ></i>
              </a>
              <ul
                v-if="Object.keys(level1Obj[level1]).length !== 0"
                class="sub-menu"
              >
                <li
                  v-for="(level2, i2) in Object.keys(level1Obj[level1])"
                  :key="i2"
                >
                  <a @click="filter(level1, level2)">
                    {{ level2 }}
                    <i
                      v-if="Object.keys(level1Obj[level1][level2]).length !== 0"
                      :class="[
                        'fas',
                        isSmallerScreen ? 'fa-caret-down' : 'fa-caret-right',
                      ]"
                    ></i>
                  </a>
                  <ul
                    v-if="Object.keys(level1Obj[level1][level2]).length !== 0"
                    class="sub-menu"
                  >
                    <li
                      v-for="(level3, i3) in Object.keys(
                        level1Obj[level1][level2]
                      )"
                      :key="i3"
                    >
                      <a @click="filter(level1, level2, level3)">
                        {{ level3 }}
                        <i
                          v-if="
                            Object.keys(level1Obj[level1][level2][level3])
                              .length !== 0
                          "
                          :class="[
                            'fas',
                            isSmallerScreen
                              ? 'fa-caret-down'
                              : 'fa-caret-right',
                          ]"
                        ></i>
                      </a>
                      <ul
                        v-if="
                          Object.keys(level1Obj[level1][level2][level3])
                            .length !== 0
                        "
                        class="sub-menu"
                      >
                        <li
                          v-for="(level4, i4) in Object.keys(
                            level1Obj[level1][level2][level3]
                          )"
                          :key="i4"
                        >
                          <a @click="filter(level1, level2, level3, level4)">
                            {{ level4 }}
                            <i
                              v-if="
                                Object.keys(
                                  level1Obj[level1][level2][level3][level4]
                                ).length !== 0
                              "
                              :class="[
                                'fas',
                                isSmallerScreen
                                  ? 'fa-caret-down'
                                  : 'fa-caret-right',
                              ]"
                            ></i>
                          </a>
                          <ul
                            v-if="
                              Object.keys(
                                level1Obj[level1][level2][level3][level4]
                              ).length !== 0
                            "
                            class="sub-menu"
                          >
                            <li
                              v-for="(level5, i5) in Object.keys(
                                level1Obj[level1][level2][level3][level4]
                              )"
                              :key="i5"
                            >
                              <a
                                @click="
                                  filter(level1, level2, level3, level4, level5)
                                "
                              >
                                {{ level5 }}
                              </a>
                            </li>
                          </ul>
                        </li>
                      </ul>
                    </li>
                  </ul>
                </li>
              </ul>
            </li>
          </ul>
        </li>
      </ul>
    </nav>
  </div>
</template>

<script>
export default {
  name: "TopCategoriesMenu",
  data() {
    return {
      tree: {},
      level1Obj: [],
      dispL1: "Todas",
      dispL2: "",
      dispL3: "",
      dispL4: "",
      dispL5: "",
      showingBarMenu: false,
    };
  },
  computed: {
    isSmallerScreen() {
      return window.innerWidth <= 850;
    },
  },
  watch: {
    categories: {
      handler: function () {
        this.createLevelTree(this.categories);
      },
      deep: true,
    },
  },
  props: {
    categories: {
      type: Array,
      required: true,
    },
  },
  emits: ["filter"],
  methods: {
    toggleBarMenu() {
      this.showingBarMenu = !this.showingBarMenu;

      //set display to id nav-list-show-hide
      const navList = document.getElementById("nav-list-show-hide");
      const bars = document.getElementById("bars-show-hide");
      const times = document.getElementById("times-show-hide");
      if (this.showingBarMenu) {
        navList.style.display = "block";
        bars.style.display = "none";
        times.style.display = "block";
      } else {
        navList.style.display = "none";
        bars.style.display = "block";
        times.style.display = "none";
      }
    },
    createLevelTree(data) {
      const tree = {};

      data.forEach((item) => {
        let currentLevel = tree;
        for (let i = 1; i <= 5; i++) {
          const levelKey = `Level${i}`;
          const levelValue = item[levelKey];
          if (!levelValue) break;
          if (!currentLevel[levelValue]) currentLevel[levelValue] = {};
          currentLevel = currentLevel[levelValue];
        }
      });

      this.tree = tree;
      this.level1Obj = tree;
    },
    filter(l1, l2, l3, l4, l5) {
      (this.dispL1 = l1),
        (this.dispL2 = l2),
        (this.dispL3 = l3),
        (this.dispL4 = l4),
        (this.dispL5 = l5);
      //find on categories array the level code according to the level name
      let filter = {};
      if (l1 && l1 !== "Todas") {
        filter.Level1 = this.categories.find(
          (item) => item.Level1 === l1
        ).Level1Code;
      }
      if (l2) {
        filter.Level2 = this.categories.find(
          (item) => item.Level2 === l2
        ).Level2Code;
      }
      if (l3) {
        filter.Level3 = this.categories.find(
          (item) => item.Level3 === l3
        ).Level3Code;
      }
      if (l4) {
        filter.Level4 = this.categories.find(
          (item) => item.Level4 === l4
        ).Level4Code;
      }
      if (l5) {
        filter.Level5 = this.categories.find(
          (item) => item.Level5 === l5
        ).Level5Code;
      }
      this.$emit("filter", filter);
    },
  },
};
</script>
<style scoped>
*,
*::before,
*::after {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}


a {
  text-decoration: none;
}

ul {
  list-style: none;
}

.menu-tittle {
  display: none;
}

.container {
  width: 100%;
  max-height: 117rem;
  margin: 0 auto;
  padding: 0 0.8rem;
}

.menu {
  color: black !important;
}


.menu-icons {
  color: #fff;
  position: absolute;
  right: 2rem;
  transform: translateY(-50%);
  cursor: pointer;
  z-index: 1500;
  display: none;
}

.fa-bars {
  font-size: 3rem;
}
.fa-times {
  font-size: 3rem;
}

nav {
  display: flex;
  align-items: center;
  width: 100%;
  height: 8rem;
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
}

.nav-list {
  display: flex;
  width: 100%;
  align-items: center;
}

.nav-list li {
  cursor: pointer;
  line-height: 8rem;
  position: relative;

}

.sub-menu li {
  line-height: 2rem;
}

.nav-list a {
  display: block;
  color: #eee;
  padding: 0 1.5rem;
  font-size: 1rem;
  text-transform: uppercase;
  transition: color 650ms;

}

.nav-list .sub-menu a {
  font-size: 0.9rem;
}

.nav-list a:hover {
  color: #FFF200;
}


.sub-menu {
  width: 15rem;
  display: block;
  position: absolute;
  border-top: 3px solid #FFF200;
  background-color: #333;
  z-index: 100;
  top: 5rem;
  opacity: 0;
  visibility: hidden;
  transition: all 650ms ease;
}

.sub-menu::before {
  content: "";
  position: absolute;
  top: -2.5rem;
  left: 3rem;
  border: 1.2rem solid transparent;
  border-bottom-color: #FFF200;
}

.sub-menu .sub-menu::before {
  top: -0.09rem;
  left: -2.5rem;
  border: 1.2rem solid transparent;
  border-right-color: #FFF200;
}

.sub-menu .sub-menu {
  border-top: none;
  border-left: 1px solid #646C75;
  top: 0;
  left: 160%;
}


.nav-list li:hover > .sub-menu {
  top: 8rem;
  opacity: 1;
  visibility: visible;
}

.sub-menu li:hover > .sub-menu {
  top: 0;
  left: 100%;

}

li.move-right {
  margin: auto 0 auto auto;
  line-height: initial;
}

@media screen and (max-width: 850px) {
  .nav-list {
    position: fixed;
    width: 45vw;
    top: 0;
    left: 0;
    height: 100vh;
    display: none;
    flex-direction: column;
    align-items: initial;
    background-color: #191919;
    z-index: 1000;

  }
  .menu-tittle {
    display: block;
    color: #fff;
    font-size: 2rem;
    margin: 2rem 2rem 5rem 1rem;
    padding-bottom: 1rem;
    border-bottom: 2px solid #FFF200;
  }

  .menu {
    color: white !important;
  }

  .nav-list li {
    line-height: 2rem;
  }

  .sub-menu {
    background-color: #191919;
    position: initial;
    border: 1px solid transparent;
    border-left-color: #646C75;
    margin-left: 1rem;
    max-height: 0;
  }
  .sub-menu::before {
    display: none;
  }

  .nav-list li:hover > .sub-menu {
    max-height: initial;
    opacity: 1;
    visibility: visible;
  }

  li.move-right {
    margin: 0 auto 0 0;
    line-height: initial;
  }

  .menu-icons {
    display: block;
    top: -8rem;
  }

  .fa-times {
    display: none;
  }

  .nav.active .fa-bars {
    display: block;
  }

  .nav.active .nav-list {
    display: flex;
  }

}

</style>
